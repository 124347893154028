import axios from 'axios'
import environment from "@/assets/js/environment";

const rasaApi = axios.create({baseURL: environment.rasaApi})
const quitejaApi = axios.create({baseURL: environment.quitejaApi})
const httpAuth = axios.create({baseURL: environment.authUrl})
const httpApps = axios.create({baseURL: environment.appsUrl})
function axiosBase(url) { return axios.create({baseURL: url}) }

rasaApi.interceptors.response.use(
  res => {
    return res
  },
  err => {
    if (err.response && err.response.status == 401) {
      window.location = environment.authUrl
      return Promise.reject(err)
    } else {
      return Promise.reject(err)
    }
  }
)

export default {
  httpAuth,
  httpApps,
  axiosBase,
  rasaApi,
  quitejaApi
}