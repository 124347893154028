import router from '@/router'
import RasaSessionsService from '@/services/sessions'
import RasaServicesService from '@/services/services'

const state = {
  currentService: null,
  services: [],
  currentServiceType: null,
  prioritizedAndTransformTypes: [],
  filteredServices: [],
  sessionsCount: [],
}

const mutations = {
    resetServices(state) {
        state.services = []
    },
    createService(state, payload) {
        state.services = payload
    },
    setCurrentService(state, payload) {
        state.currentService = payload
    },
    setCurrentServiceType(state, payload) {
        state.currentServiceType = payload
    },
    setPriorizeAndTransformedTypes(state, payload) {
        state.prioritizedAndTransformTypes = payload
    },
    setFilteredServices(state, payload) {
        state.filteredServices = payload
    },
    addSessionCount(state, payload) {
        state.sessionsCount.push(payload)
    }
}

const actions = {
    async getServices({ rootState, commit, state, dispatch }) {
        let services = []
        let result = await RasaServicesService.get_services().then(res => res.data.services)
        for (var idx in result) {
            let params = { service_id: result[idx].id }
            result[idx].pendings = await RasaSessionsService.get_sessions_pending_count(params).then(res => res.data.total)
            result[idx].count_all = await RasaSessionsService.get_count_by_service(result[idx].id).then(res => res.data.sessions_count)
            services.push(result[idx])
        }
        commit('createService', services)            
    },
    getServicesTypes({ state, commit }) {
        var new_array = JSON.parse(JSON.stringify(state.services))
        const unique_types = [... new Set(new_array.map(item => item.type))]
        const prioritize_and_transform_types = []
        
        if (unique_types.includes('WHATSAPP')) {
            prioritize_and_transform_types.push({type: 'WHATSAPP',value: 'WhatsApp'})
        }
        if (unique_types.includes('SMS')) {
            prioritize_and_transform_types.push({type: 'SMS',value: 'Sms'})
        }
        if (unique_types.includes('EMAIL')) {
            prioritize_and_transform_types.push({type: 'EMAIL',value: 'Email'})
        }
        if (unique_types.includes('SITE')) {
            prioritize_and_transform_types.push({type: 'SITE',value: 'Site'})
        }
        commit('setPriorizeAndTransformedTypes', prioritize_and_transform_types)
    },
    selectCurrentService({ state, commit }, id) {
        const newService = state.services.find(el => el.id == id)
        commit('setCurrentService', newService)
    },
    getServicesByType({ state, commit }) {
        var new_array = JSON.parse(JSON.stringify(state.services))
        const services_filtered = new_array.filter(function (el) {
            return el.type == state.currentServiceType
        })
        let sortedServices = services_filtered.sort((service1, service2) => {
            if(service1.id < service2.id) return -1
            return 1
        })
        commit('setFilteredServices', sortedServices)
    },
    async checkServices({ rootState, state, commit, dispatch }) {
        if (state.services.length == 0) {
            await dispatch('getServices')
            await dispatch('getServicesTypes')
            await dispatch('getServicesByType')
        }
        if (!router.app._route.params.service_id) {
            router.push("/not-found").catch(() => { })
        } else {
            const service_id = parseInt(router.app._route.params.service_id)
            var new_array = JSON.parse(JSON.stringify(state.services))
            const idx = new_array.indexOf(new_array.find((obj) => obj.id == service_id))
            if (idx == -1) {
                router.push("/not-permission").catch(() => { })
            } else if (!new_array[idx].active) {
                router.push("/not-found").catch(() => { })
            } else {
                commit('setCurrentService', new_array[idx])
                commit('setCurrentServiceType', new_array[idx].type)
            }
        }

    },
}

const getters = {
    services: (state) => state.services,
    currentService: (state) => state.currentService,
    currentServiceType: (state) => state.currentServiceType,
    prioritizedAndTransformTypes: (state) => state.prioritizedAndTransformTypes,
    filteredServices: (state) => state.filteredServices,
  }

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};