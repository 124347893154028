import { version } from '../../package.json';

const state = {
  FCMToken: null,
  intents: [],
  snackbar: {
    message: '',
    show: false,
    timeout: 500,
    important: false,
    href: null,
    isLoading: false,
    icon: '',
    iconColor: '',
  },
  cachedResult: null,
  errorMessage: null,
  newMessage: null,
  testMessage: null,
  isEditingMode: false,
  packageVersion: version,
  windowWidth: window.innerWidth,
}

const mutations = {
  setwindowWidth(state, payload) {
    state.windowWidth = payload
  },
  showSnackbar(state, payload) {
    console.log(payload);
    // Pode ser melhorado, colocado mais informações como cor e duração,
    // ou se mostra o fechar ou não ou ser utilizado o plugin vue-toasted
    if (state.snackbar.show) {
      state.snackbar.show = false
    }
    setTimeout(() => {
      if (typeof (payload.message) == 'string' || typeof (payload) == 'string') {
        state.snackbar.message = payload.message ? payload.message : payload
        state.snackbar.href = null
        state.snackbar.timeout = 500
        state.snackbar.important = false
        state.snackbar.show = true
        state.snackbar.isLoading = payload.isLoading
        state.snackbar.icon = payload.icon
        state.snackbar.iconColor = payload.iconColor

      } else {
        state.snackbar.message = payload.message
        state.snackbar.href = payload.href
        state.snackbar.show = true
        // state.snackbar.timeout = payload.timeout != null ? payload.timeout : 500
        state.snackbar.important = payload.important ? payload.important : false

      }
    }, 500)
  },
  setCacheLastResult(state, payload) {
    state.cachedResult = payload
  },
  setErrorMessage(state, payload) {
    state.errorMessage = payload
  },
  setEventNewMessage(state, payload) {
    state.newMessage = payload
    setTimeout(() => {
      state.newMessage = null
    }, 100)
  },
  setIsEditingMode(state, payload) {
    state.isEditingMode = payload
  },
  handleTestMessage(state, payload) {
    state.testMessage = payload
    setTimeout(() => {
      state.testMessage = null
    }, 100)
  }
}

const getters = {
  getwindowWidth(state) {
    return state.windowWidth
  },
  getFCMToken(state) {
    return state.FCMToken
  },
  getIntents(state) {
    return state.intents
  },
  getSnackbarInstance(state) {
    return state.snackbar
  },
  getErrorMessage(state) {
    return state.errorMessage
  },
  getCachedResult(state) {
    return state.cachedResult
  },
  getEventNewMessage(state) {
    return state.newMessage
  },
  getEventTestMessage(state) {
    return state.testMessage
  },
  getIsEditingMode(state) {
    return state.isEditingMode
  },
  appVersion(state) {
    return state.packageVersion
  }
}

const actions = {
  detectWindowWidth({ commit }) {
    window.addEventListener('resize', () => {
      commit('setwindowWidth', window.innerWidth);
    })
  },
}

// const actions = {}

export default {
  state,
  mutations,
  getters,
  actions,
};