import RasaSessionsService from '@/services/sessions'
const state = {
  list: [],
  selected: {
    id: null,
    name: null,
    type: null,
  },
  loading: false,
  filters: null,
}
const mutations = {
  setSelected(state, item) {
    state.selected = item
  },
  clearSelected(state) {
    state.selected = {
      id: null,
      name: null,
      type: null,
    }
  },
  setList(state, list) {
    state.list = list
  },
  setFilters(state, filters) {
    state.filters = filters
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  clearList(state){
    state.list = []
  }
}
const actions = {
  async getAll({ dispatch, state, commit }, params) {
    commit('setLoading', true)
    commit('clearList')
    await RasaSessionsService.get_sessions_by_search(params).then(res => {
      commit('setList', res.objects)
      state.loading = false
    }).catch(() => {
      commit('showSnackbar', 'Ocorreu um erro ao buscar as canais', {root: true})
      state.loading = false
    })
  },
}
const getters = {
  list(state) { return state.list },
  loading(state) { return state.loading },
  selected(state) { return state.selected },
  filters(state) { return state.filters },
  channelBuildVersion: (state) => (hash) => {
    return state.list.filter(item => item.agent_build_hash == hash)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};