import PrismEditor from "vue-prism-editor";
import "vue-prism-editor/dist/VuePrismEditor.css"; // import the styles
import "prismjs";

// Clear themes
import "prismjs/themes/prism-solarizedlight.css";
// import "prismjs/themes/prism-coy.css";
// import "prismjs/themes/prism.css";

// Dark themes
// import "prismjs/themes/prism-tomorrow.css";
// import "prismjs/themes/prism-okaidia.css";
// import "prismjs/themes/prism-twilight.css";
// import "prismjs/themes/prism-dark.css";
import Vue from 'vue'

Vue.component("prism-editor", PrismEditor);