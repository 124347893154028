import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import { name, version } from '@/../package.json';

import './plugins/vue-socketio'
import './plugins/infiniteScroll'
import './plugins/json-viewer'
import './plugins/axios'
import './plugins/moment'
import './plugins/clipboard'
import './plugins/validatorForm'
import './plugins/prism-editor'
import './plugins/toasted'
import './plugins/infiniteLoading'
import './plugins/viewer'
import './plugins/vue-shortkey'
import './plugins/v-infinite-loader'

import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/js/filters'
import '@/assets/js/directives'
import '@/assets/js/registerServiceWorker'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import { VueMaskDirective } from 'v-mask'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import _ from 'lodash'

import * as Sentry from "@sentry/vue";
import { Vue as VueIntegration } from "@sentry/integrations";

if (process.env.NODE_ENV === 'production') {
  console.debug("Configurando o Sentry");

  // Integração com o Sentry
  Sentry.init({
    dsn: 'https://76826851421a4e6a817e62ce2d7e4c3e@o92838.ingest.sentry.io/6078181',
    environment: (window.location.host == 'dodo.gorillascode.com') ? 'production' : 'staging',
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    release: name + '@' + version,
    sendDefaultPii: true,
  });
}

Vue.prototype.lodash = _

Vue.directive('mask', VueMaskDirective);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false

Vue.use(require('vue-moment'));

Vue.mixin({
  methods: {
    verifyImg(imgUrl){
      return imgUrl ? imgUrl : "img/default.png"
    },
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
