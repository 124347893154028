import axios from '@/plugins/axios'

export default {
  get(id) {
    return axios.rasaApi.get(`/initiative/${id}`)
  },
  search(params) {
    return axios.rasaApi.get('/initiative/search', { params })
  },
  save(data) {
    if (data.id) {
      return axios.rasaApi.patch('/initiative/' + data.id, data)
    }
    return axios.rasaApi.post('/initiative', data)
  }
}