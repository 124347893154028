/* eslint-disable */

import axios from '@/plugins/axios'
import environment from "@/assets/js/environment";
import * as Sentry from "@sentry/browser";

import UserService from '@/services/users'

const state = {
    token: null,
    loggedUser: null,
}

const mutations = {
    setToken(state, token) {
        state.token = token
        localStorage.setItem('DODO_TOKEN', token)
        axios.rasaApi.defaults.headers.common['Authentication-Token'] = token
    },

    setLoggedUser(state, user) {
        state.loggedUser = user
        localStorage.setItem('DODO_USER', JSON.stringify(user))
    },
}

const actions = {
    auth({commit, dispatch}, token) {
        return new Promise( async (resolve, reject) => {
            if (token) {
                commit('setToken', token)
            } else {
                console.log('Autenticação sem token')
                window.location = environment.authUrl
            }
            await UserService.getUserMe().then(res => {
                commit('setLoggedUser', res.data)
                resolve()
            }).catch( () => {
                // window.alert('desconectando')
                dispatch('logout')
            })
        })
    },
    logout() {
        state.user = null
        state.token = null
        localStorage.removeItem('DODO_TOKEN') // if the request fails, remove any possible user token if possible
        localStorage.removeItem('DODO_USER')
    },
    check_auth() {
        if (state.user && state.token) {
            return true
        }

        const token = localStorage.getItem('DODO_TOKEN')
        const user = JSON.parse(localStorage.getItem('DODO_USER'))
        if (token && user) {
            state.token = token
            state.loggedUser = user
            axios.rasaApi.defaults.headers.common['Authentication-Token'] = token
            Sentry.setUser({
                id: user.id,
                username: user.name,
              });
            return true
        }
        return false
    }
}

const getters = {
    getLoggedUser(state) {
        return state.loggedUser
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
};