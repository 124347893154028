import QuickRepliesService from "@/services/quickReply";
import { create } from "lodash";

const state = {
  list: [],
  selected: {},
  loading: false,
  totalItems: null,
  activeSwitch: false,

}
const mutations = {
  setSelected(state, item) {
    state.selected = item
  },
  clearSelected(state) {
    state.selected = {}
  },
  setList(state, list) {
    state.list = list.objects
    state.totalItems = list.num_results
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  clearList(state){
    state.list = []
  },
  setActiveSwitch(state){
    state.activeSwitch = !state.activeSwitch
  }
}
const actions = {
  async getAll({ commit }, params) {
    commit('setLoading', true)
    commit('clearList')
    await QuickRepliesService.search(params).then(res => {
      commit('setList', res.data)
    }).finally(() => {
      commit('setLoading', false)
    })
  },
  async get({ commit }, quick_id) {
    commit('setLoading', true)
    await QuickRepliesService.getById(quick_id).then(res => {
      commit('setSelected', res.data)
      commit('setLoading', false)
    })
  },
  async create({ commit }, data) {
    await QuickRepliesService.create(data).then((res) => {
      commit('setSelected', res.data)
      commit('showSnackbar', `Resposta rapida ${res.id} deletada`, {root: true})
    })
  },
  async delete({ commit }, quick_id) {
    await QuickRepliesService.delete(quick_id).then((res) => {
      commit('showSnackbar', `Resposta rapida ${res.name} deletada`, {root: true})
    })
  },
  async search({ commit }, params){
    QuickRepliesService.search(params).then((res) => {
      commit('setList', res.data)
    })
  },
  async save({ commit }, params){
    return new Promise((resolve, reject) => {
      if(params.id){
        QuickRepliesService.editItem(params).then(() => {
          resolve()
          commit('showSnackbar', `Resposta rapida editada`, {root: true})
        }).catch(err => {
          reject(err)
          commit('showSnackbar', `${err}`, {root: true})
        })
      } else {
        params.active = true
        QuickRepliesService.create(params).then(() => {
          resolve()
          commit('showSnackbar', `Resposta rapida criada`, {root: true})
        }).catch(err => {
          reject(err)
          commit('showSnackbar', `${err}`, {root: true})
        })
      }
    })
  },
  async validateKey({commit}, key){
    return new Promise((resolve, reject) => {
      QuickRepliesService.validateKey(key).then((res) => {
        resolve(res)
      }).catch(err => reject(err))
    })
  }
}
const getters = {
  list(state) { return state.list },
  total(state) { return state.totalItems },
  loading(state) { return state.loading },
  selected(state) { return state.selected },
  activeSwitch(state) { return state.activeSwitch },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};