import axios from '@/plugins/axios'
import environment from "@/assets/js/environment";

export default {
    createFile(files, folders) {
        return axios.rasaApi.post('/message_sent/generate_s3_signature', {files: files, folders: folders})
    },
    upload(url, formData) {
        return axios.axiosBase(url).post('', formData)
    },

}