import UploadService from "@/services/upload"

const state = {
  created: null,
  preview: null,
  loading: null,
}
const mutations = {
  setCreated(state, item) {
    state.created = item
  },
  setPreview: (state, payload) => state.preview = payload,
  setLoading: (state, payload) => state.loading = payload,
  removePreview: (state) => state.preview = null,
}
const actions = {
  async create({ commit }, params) {
    commit('setLoading', true)
    return new Promise((resolve) => {
      UploadService.createFile(params.info, params.type).then((res) => {
        commit('setCreated', res.data)
        resolve(res.data)
      })
    })
  },
  async upload({ commit }, params){
    await UploadService.upload(params.url, params.data)
    commit('setLoading', false)
  },
  async download({}, params){
    const data = await fetch(params.url)
    const blob = await data.blob()
    const objectUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')

    link.setAttribute('href', objectUrl)
    link.setAttribute('download', params.file_name)
    link.style.display = 'none'

    document.body.appendChild(link)
  
    link.click()
  
    document.body.removeChild(link)
  },

}
const getters = {
  created: (state) => { return state.created },
  preview: (state) => { return state.preview },
  loading: (state) => { return state.loading },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};