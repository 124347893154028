/* eslint-disable no-console */

import { register } from 'register-service-worker'
import Vue from 'vue'

const notifyUserAboutUpdate = worker => {
  let action = [
    {
      text: 'Cancelar',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    },{
      text: 'Atualizar',
      onClick: (e, toastObject) => {
        localStorage.removeItem('DODO_TOKEN') // if the request fails, remove any possible user token if possible
        localStorage.removeItem('DODO_USER')
        worker.postMessage({action: 'skipWaiting'})
        toastObject.goAway(0)
      }
    }
  ]

  Vue.toasted.show('Atualização disponivel', {
    action,
    theme: "toasted-primary",
    position: "top-left",
    className: 'toast-class',
    containerClass: 'container-toast-class'
  });

}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      notifyUserAboutUpdate(registration.waiting)
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

  var refreshing;
  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return;
    window.location.reload()
    refreshing = true
  })
}
