import router from '@/router'
import RasaHealthCheckService from '@/services/healthCheck'

const state = {
    errorStack: []
}

const mutations = {
    setErrorStack(state, newStack) {
        state.errorStack = newStack
    }
}

const actions = {
    searchNewErrors({ commit }) {
        RasaHealthCheckService.get().then((res) => {
            if (res.data.errors.length > 0) {
                commit('setErrorStack', res.data.errors)
                router.push('/health_check').catch(() => {})
            } 
        })
    }
}

const getters = {
    errorStack: (state) => state.errorStack,
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};