<template>
  <v-app dark>
    <router-view v-if="$store.state.auth.loggedUser && $store.state.auth.loggedUser.id"></router-view>

    <v-container v-else fill-height fluid>
      <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <img
              src="@/assets/logo.png"
              class="logo"
              title="Logo" />

            <v-progress-circular
              indeterminate
              color="primary"
              class="d-flex mx-auto"
            ></v-progress-circular>
          </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
/* eslint-disable no-console */
import { mapActions, mapGetters, mapMutations } from "vuex";
import environment from "@/assets/js/environment";
import { utils } from "@/assets/js/utils";

export default {

  name: 'App',

  async created() {
    console.log('Versao:', this.$store.getters.appVersion)
    if (environment.host == 'localhost') {
      this.setDevIcon()
    }

    this.$vuetify.theme.dark =  utils.getCookie("dark_mode") === "true";

    await this.getServices();

    if (this.$route.params.service_id) {
      this.selectCurrentService(this.$route.params.service_id)
    }

    await this.searchNewErrors()
    await this.getServicesTypes();
    await this.getAllUsers();
    await this.getInitiatives();
    await this.getQuickReplies();
    this.detectWindowWidth();

  },
  computed: {
    ...mapGetters("rasaServices", {
      currentService: "currentService",
      services: "services",
    }),
  },
  methods: {
    ...mapActions('rasaHealthCheck', {
      searchNewErrors: 'searchNewErrors',
    }),
    ...mapActions('agent', {
      getAgent: 'getAgent',
    }),
    ...mapActions({
      detectWindowWidth: 'detectWindowWidth',
    }),
    setDevIcon() {
      let favicon = document.querySelector('link[rel="icon"]')
      favicon.href = `img/icons/${environment.host}/favicon-${favicon.sizes}.png`
    },

    ...mapActions("rasaServices", {
      getServices: "getServices",
      getServicesTypes: "getServicesTypes",
      selectCurrentService: "selectCurrentService",
    }),
    ...mapActions('rasaUsers', {
      getAllUsers: 'getUsers',
    }),
    ...mapActions("rasaInitiative", {
      getInitiatives: "getAll",
    }),
    ...mapActions("rasaQuickReplyList", {
      getQuickReplies: "getAll",
    }),
  },
  watch: {
    "$route.params.service_id"(newValue, oldValue) {
      if (!newValue) {
        this.selectCurrentService()
        return;
      }
      if (newValue == oldValue) {
        return;
      }
      this.selectCurrentService(this.$route.params.service_id)
    },
    "$vuetify.theme.dark"(newValue, oldValue){
      var r = document.querySelector(':root');
      if (newValue) {
        r.style.setProperty('--main-bg', '#111b21');
        r.style.setProperty('--theme-primary-bg', '#111b21');
        r.style.setProperty('--theme-secondary-bg', '#202c33');
        r.style.setProperty('--theme-tertiary-bg', '#2a3942');
        r.style.setProperty('--mdc-theme-text-primary-on-background', '#fff');
        r.style.setProperty('--theme-vlist-focus', '#fff');
      } else {
        r.style.setProperty('--main-bg', '#f0f2f5');
        r.style.setProperty('--theme-primary-bg', '#fff');
        r.style.setProperty('--theme-secondary-bg', '#f0f2f5');
        r.style.setProperty('--theme-tertiary-bg', '#f0f2f5');
        r.style.setProperty('--mdc-theme-text-primary-on-background', '#000000');
        r.style.setProperty('--theme-vlist-focus', '#1e5c5ce8');
        
      }
    }
  }
}
</script>

<style lang="scss">
@import "./global.scss";
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0) inset !important;
}
.theme--light.v-list:not(.ignore-vue-based) {
  background: var(--theme-tertiary-bg) !important;
}
.theme--dark.v-list:not(.ignore-vue-based) {
  background: var(--theme-tertiary-bg) !important;
}

.theme--light.v-picker__body {
  background: var(--theme-tertiary-bg) !important;
}

.theme--dark.v-picker__body {
  background: var(--theme-tertiary-bg) !important;
}
.theme--dark.v-card:not(.ignore-vue-based) {
  background: var(--theme-secondary-bg) !important;
}

.theme--dark.v-card:not(.ignore-vue-based) {
  background: var(--theme-secondary-bg) !important;
}

.intent-table {
  tbody {
    overflow: auto;
  }
  .v-data-table__wrapper {
    overflow: auto;
    height: calc(100vh - 144px) !important;
    color: #000000;
  }
  .v-data-footer {
    padding-right: 80px !important;
    margin-right: 0px !important;
  }
}
.regex-table {
  tbody {
    overflow: auto;
  }
  .v-data-table__wrapper {
    overflow: auto;
    height: calc(100vh - 130px) !important;
  }
  .v-data-footer {
    padding-right: 80px !important;
    margin-right: 0px !important;
  }
}
// Scss referente a data table conversas e mensagens recebidas e conversas (canais)
.message,
.quick-reply-list,
.initiative-list,
.conversation {
  .v-data-table__wrapper {
    height: calc(100vh - 128px) !important;
    // &::-webkit-scrollbar{width: 1px;    }

    // &::-webkit-scrollbar-thumb{
    // background: black;
    // border-radius: 20px;}
  }
  .message-table-body {
    overflow: auto;
    height: calc(100vh - 265px);
  }
}
// Fim referente a data table conversas e mensagens recebidas (canais)
html {
  overflow: hidden !important;
}
html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.logo {
  height: 80px;
  margin-bottom: 16px;
  width: 80px;
}

.container-toast-class {
  top: 1% !important;
  left: 5% !important;
}

.toast-class {
  font-family: "roboto" !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  .action {
    font-size: 14px !important;
    font-weight: 500;
    color: #e91e63 !important;
    text-decoration: none !important;
    padding: 4px;

    &:hover {
      background-color: #424242 !important;
    }
  }
}
#build-history-table {
  .v-data-table__wrapper {
    height: 100%;
    overflow-y: auto !important;
  }
}
</style>
