import store from '@/store/index'

export const utils = {
  string_to_hash: function (string) {
    let stringHash = 0;
    for (let i = 0; i < string.length; i++) {
      stringHash = string.charCodeAt(i) + ((stringHash << 5) - stringHash);
    }

    return stringHash;
  },

  integer_to_rgba(integer) {
    let rgba = ((integer >> 24) & 0xFF).toString(16) +
      ((integer >> 16) & 0xFF).toString(16) +
      ((integer >> 8) & 0xFF).toString(16) +
      (integer & 0xFF).toString(16);

    return rgba;
  },

  adjust_brightness(color, adjustment = -10) {
    let num = parseInt(color, 16),
      amount = Math.round(2.55 * adjustment),
      R = (num >> 16) + amount,
      G = (num >> 8 & 0x00FF) + amount,
      B = (num & 0x0000FF) + amount;
    return (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 +
      (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 +
      (B < 255 ? B < 1 ? 0 : B : 255))
      .toString(16)
      .slice(1);
  },
  string_to_color(string) {
    let hash = utils.string_to_hash(string);
    let rgba = utils.integer_to_rgba(hash);
    let final = utils.adjust_brightness(rgba);

    return final;
  },
  colorFromString: function (str) {
    return `#${utils.string_to_color(str)}`;
  },
  truncate(value, limit) {
    if (!value) {
      return value
    }
    return value.length > limit ? value.substring(0, limit) + '...' : value
  },
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
}

