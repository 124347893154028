import axios from '@/plugins/axios'
import axiosPlugin from '@/plugins/axios'

export default {
    CancelToken: axios.CancelToken,
    cancel: function () { },

    search(params) {
        return axiosPlugin.rasaApi.get(`/quick_reply/search`, { params })
    },

    getList(params) {
        return axiosPlugin.rasaApi.get('quick_reply', {params})
    },

    getById(qr_id) {
        return axiosPlugin.rasaApi.get('quick_reply/' + qr_id)
    },

    editItem(data) {
        console.log("data", data)
        let payload = {
            "description": data.description,
            "name": data.name,
            "key": data.key,
            "active": data.active,
            "replies": data.replies

        }
        console.log("payload", payload)
        return axiosPlugin.rasaApi.patch('quick_reply/' + data.id, payload)
    },

    create(data) {
        return axiosPlugin.rasaApi.post('quick_reply', data)
    },

    delete(qr_id) {
        return axiosPlugin.rasaApi.delete('quick_reply/' + qr_id)
    },

    validateKey(query) {
        return axiosPlugin.rasaApi.get('quick_reply/validate?key=' + query)
    },


}
