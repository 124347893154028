import Vue from "vue";
import Vuex from "vuex";

import rasaSessions from "../pages/CustomerServiceRasa/store/rasaSessions.module"
import rasaSearchSessions from "../pages/CustomerServiceRasa/store/rasaSearchSessions.module"
import rasaClosedSessions from "../pages/CustomerServiceRasa/store/rasaClosedSessions.module"
import rasaConversation from "../pages/CustomerServiceRasa/store/rasaConversation.module"
import rasaEvents from "../pages/CustomerServiceRasa/store/rasaEvents.module"
import rasaUpload from "../pages/CustomerServiceRasa/store/rasaUpload.module"
import rasaQuickReplyList from "../pages/CustomerServiceRasa/store/rasaQuickReplyList.module"
import rasaQuickReply from "../pages/CustomerServiceRasa/pages/QuickReply/store.module"
import rasaInitiative from "../pages/CustomerServiceRasa/store/rasaInitiative.module"
import rasaServices from "../pages/CustomerServiceRasa/store/rasaServices.module"
import rasaUsers from "../pages/CustomerServiceRasa/store/rasaUsers.module"
import rasaChannelList from "../pages/CustomerServiceRasa/store/rasaChannelList.module"
import rasaConversationList from "../pages/CustomerServiceRasa/store/rasaConversationList.module"
import rasaHealthCheck from "../pages/CustomerServiceRasa/store/rasaHealthCheck.module"

// Others
import auth from "./auth.module"
import snackbar_store from "./snackbar.module"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        snackbar_store,
        
        // CustomService2
        rasaSessions,
        rasaConversation,
        rasaClosedSessions,
        rasaSearchSessions,
        rasaEvents,
        rasaUpload,
        rasaQuickReplyList,
        rasaQuickReply,
        rasaInitiative,
        rasaServices,
        rasaUsers,
        rasaChannelList,
        rasaConversationList,
        rasaHealthCheck,
    }
});
