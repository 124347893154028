import InitiativeService from '@/services/initiative'
import router from '@/router'

const state = {
  list: [],
  loading: false,
  template: null,
  selected: {
    id: null, key: null
  },
}
const mutations = {
  setSelected(state, item) {
    state.selected = item
  },
  clearSelected(state) {
    state.selected = {
      id: null, key: null
    }
  },
  setList(state, list) {
    state.list = list
  },
  setTemplate(state, template){
    state.template = template
  }
}
const actions = {
  async get({ state, commit }, item_id) {
    await InitiativeService.get(item_id).then(res => {
      commit('setSelected', res.data)
      state.loading = false

    }).catch(() => {
      state.$store.commit('showSnackbar', 'Ocorreu um erro ao buscar a iniciativa')
      state.loading = false
    })
  },
  async getAll({ rootState, state, commit, dispatch}) {
    state.loading = true
    let params = {
      limit: 999, 
      order: 'name',
      service_id: router.app._route.params.service_id
    }

    await InitiativeService.search(params).then(res => {
      commit('setList', res.data.objects)
      state.loading = false
    }).catch(() => {
      state.$store.commit('showSnackbar', 'Ocorreu um erro ao buscar as iniciativa')
      state.loading = false
    })
  },
  async search({ rootState, state, commit }, value) {
    state.loading = true
    let params = {
      limit: 999, order: 'name',
      search: value,
    }

    InitiativeService.search(params).then(res => {
      commit('setList', res.data.objects)
      state.loading = false

    }).catch(() => {
      state.$store.commit('showSnackbar', 'Ocorreu um erro ao buscar as iniciativa')
      state.loading = false
    })
  },
  async getUsedOnlyByUser({ rootState, state, commit }) {
    let params = {
      used_only_by_users: true, 
      order: 'name', 
      limit: 999,
      active: true
    }
    let response = await InitiativeService.search(params).then(res => res.data.objects)
    return response

  },  
  async save({ state, commit }, item) {
    return new Promise((resolve, reject) => {
      let initiativeToSave;
      initiativeToSave = item;
      initiativeToSave.key = item.key.toLowerCase()
      InitiativeService.save(initiativeToSave)
      .then((res) => {
          commit('setSelected', initiativeToSave)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
  })
  },
  async deactivateSelected({ state, commit }, item) {
    return new Promise((resolve, reject) => {
      let initiativeToDisable = {
        id: item.id,
        active: item.active
      };
      InitiativeService.save(initiativeToDisable)
      .then(() => {
          commit('setSelected', item)
          resolve('Desabilitado com sucesso')
        }).catch((err) => {
          reject('Erro:', err)
        })
    })
  },
}
const getters = {
  list(state) { return state.list },
  loading(state) { return state.loading },
  selected(state) { return state.selected },
  template(state) { return state.template },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};