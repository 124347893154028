const state = {
  activeSwitch: false,
  search: "",
}
const mutations = {
  setActiveSwitch(state, value) {
    state.activeSwitch = value
  },
  setSearch(state, value) {
    state.search = value
  },
}
const actions = {
  searchQuery() {
    // let params = this.makeParams();
    // params.search = this.search;
    // this.loadingMacros;
    // this.getQRs(params);
  },
}
const getters = {
  activeSwitch: (state) => { return state.activeSwitch },
  search: (state) => { return state.search },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};