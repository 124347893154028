import RasaUsersService from '@/services/users'

const state = {
    userList: []
}

const mutations = {
    loadUsers(state, user_list) {
        state.userList = user_list
    }
}

const actions = {
    async getUsers({ commit }) {
        await RasaUsersService.getAll().then(res => commit('loadUsers', res.data.users)) 
    }
}

const getters = {
    userList: (state) => { return state.userList },
}
export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
}