const host = window.location.hostname;

const configs = {
    'localhost': {
        owner: 'QuiteJá',
        ownerUrl: 'https://quiteja.com.br',
        pageTitle: 'Dodo - DEVOPS',
        socketRasaUrl: 'http://localhost:5010',
        socketOptions: '', 
        logo: 'https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwju-YP5ubngAhUZJ7kGHW3sBh0QjRx6BAgBEAU&url=https%3A%2F%2Fwww.vexels.com%2Fpng-svg%2Fpreview%2F153290%2Fprocessor-colored-stroke-icon&psig=AOvVaw1WUKC5hk0o7m5ZshcC5XiZ&ust=1550172819720274',
        externalRefUrl: 'http://localhost:8283/#!/customer/',
        authUrl: 'http://localhost:5030/oauth/login',
        appsUrl: 'http://localhost:9292/#/my-apps',
        rasaApi: 'http://localhost:5805/v1',
        backofficeUrl: 'https://backoffice.quiteja.staging.gorillascode.com',
    },
    'dodo.staging.gorillascode.com': {
        owner: 'QuiteJá',
        ownerUrl: 'https://quiteja.com.br',
        pageTitle: 'Dodo - STAGING',
        socketRasaUrl: 'https://socketio.rasa.staging.gorillascode.com',
        socketOptions: '/socket.io',
        logo: 'https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwju-YP5ubngAhUZJ7kGHW3sBh0QjRx6BAgBEAU&url=https%3A%2F%2Fwww.vexels.com%2Fpng-svg%2Fpreview%2F153290%2Fprocessor-colored-stroke-icon&psig=AOvVaw1WUKC5hk0o7m5ZshcC5XiZ&ust=1550172819720274',
        authUrl: 'https://api.rasa.staging.gorillascode.com/oauth/login',
        externalRefUrl: 'https://backoffice.quiteja.staging.gorillascode.com/customer/',
        appsUrl: 'https://auth.staging.gorillascode.com',
        rasaApi: 'https://api.rasa.staging.gorillascode.com/v1',
        backofficeUrl: 'https://backoffice.quiteja.staging.gorillascode.com',
    },
    'dodo.gorillascode.com': {
        owner: 'QuiteJá',
        ownerUrl: 'https://quiteja.com.br',
        pageTitle: 'Dodo',
        socketRasaUrl: 'https://socketio.rasa.gorillascode.com',
        socketOptions: '/socket.io',
        logo: 'https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwju-YP5ubngAhUZJ7kGHW3sBh0QjRx6BAgBEAU&url=https%3A%2F%2Fwww.vexels.com%2Fpng-svg%2Fpreview%2F153290%2Fprocessor-colored-stroke-icon&psig=AOvVaw1WUKC5hk0o7m5ZshcC5XiZ&ust=1550172819720274',
        authUrl: 'https://api.rasa.gorillascode.com/oauth/login',
        externalRefUrl: 'https://backoffice.quiteja.com.br/customer/',
        appsUrl: 'https://auth.gorillascode.com',
        rasaApi: 'https://rasa.gorillascode.com/api/v1',
        backofficeUrl: 'https://backoffice.quiteja.com.br',
    },
};

export default {
    owner: configs[host].owner,
    ownerUrl: configs[host].ownerUrl,
    pageTitle: configs[host].pageTitle,
    socketRasaUrl: configs[host].socketRasaUrl,
    socketOptions: configs[host].socketOptions,
    logo: configs[host].logo,
    authUrl: configs[host].authUrl,
    externalRefUrl: configs[host].externalRefUrl,
    appsUrl: configs[host].appsUrl,
    rasaApi: configs[host].rasaApi,
    backofficeUrl: configs[host].backofficeUrl,
}
