import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt'
import en from 'vuetify/es5/locale/en'
import colors from 'vuetify/es5/util/colors'
import 'vuetify/dist/vuetify.min.css'
import Whatsapp from '@/assets/whatsapp.svg'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      whatsapp: Whatsapp,
    }
  },
  lang: {
    locales: { en, pt },
    current: 'pt'
  },
  theme: {
    themes: {
      light: {
        primary: colors.teal.darken1,
        secondary: colors.teal.lighten4,
        accent: colors.teal.accent3,
        title: colors.grey.darken4,
        background: colors.grey.lighten5
      },
      dark: {
        primary: colors.teal.darken1,
        secondary: colors.teal.lighten4,
        accent: colors.teal.accent3,
        title: '#111b21',
        background: '#111b21'
      }
    }
  }
});