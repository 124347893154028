import Vue from 'vue'

Vue.directive('infinite-scroll', {
    // Diretiva para paginação infinita, recebe um objeto paginador no formato
    // {
    //     callback: function : função a ser chamada,
    //     disablePagination: boolean : flag onde desabilita a chamada para o callback,
    //     runOn: top/bottom string : define onde será chamado o callback
    // }
    bind(el, binding) {
        el.onscroll= () => {
            let paginator = binding.value

            if (!paginator.disablePagination) {
                if (paginator.runOn != null && paginator.runOn != undefined && paginator.runOn == 'top') {
                    if (el.scrollTop == 0) {
                        paginator.callback()
                    }

                } else {
                    let distanceToBottom = (el.scrollTop + el.offsetHeight) - el.scrollHeight;
                    
                    if (distanceToBottom > -1 && distanceToBottom < 1) {
                        paginator.callback()
                    }
                }
            }
        }
    }
})

const scrollToBottom = (el, smooth) => {
    if (typeof el.scroll === "function") {
      el.scroll({
        top: el.scrollHeight,
        behavior: smooth ? 'smooth' : 'instant'
      });
    } else {
      el.scrollTop = el.scrollHeight;
    }
  };
  
Vue.directive('auto-bottom', {
    bind: (el, binding) => {
      let scrolled = false;
  
      el.addEventListener('scroll', () => {
        scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
      });
  
      (new MutationObserver(e => {
        let config = binding.value || {};
        let pause = config.always === false && scrolled;
        if (config.scrollonremoved) {
          if (pause || e[e.length - 1].addedNodes.length != 1 && e[e.length - 1].removedNodes.length != 1) return;
        } else {
          if (pause || e[e.length - 1].addedNodes.length != 1) return;
        }
        setTimeout(scrollToBottom(el, config.smooth), config.delay || 0)
        
      })).observe(el, { childList: true, subtree: true });
    },
    inserted: scrollToBottom
  }
)

Vue.directive('bg-from-str', {
  inserted(el, binding) {
    function hashCode(str) { // java String#hashCode
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
         hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
  } 
  
    function intToRGB(i){
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return "00000".substring(0, 6 - c.length) + c;
    }

    el.style.backgroundColor = '#' + intToRGB(hashCode(binding.value))
  }
})