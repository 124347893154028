import Vue from 'vue'
import environment from "@/assets/js/environment";
// import * as io from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import VueSocketIOExt from 'vue-socket.io-extended';

import SocketIO from "socket.io-client"

const options = {
    // path: environment.socketOptions,
    transports: ['websocket'],
    // forceNew: true,
    timeout: 30000,
    forceNew: false,
    multiplex: false,
    closeOnBeforeunload: false,
    autoConnect: false,
    // extraHeaders: (cb) => {
    //     cb({ 'Authorization-Token': localStorage.getItem('DODO_TOKEN') })
    //     },
    // auth: (cb) => {
    //     cb({ 'Authorization-Token': localStorage.getItem('DODO_TOKEN') })
    //     },
    query: {
        'Authorization-Token': localStorage.getItem('DODO_TOKEN')
    }

}

export const rasaWs = SocketIO(environment.socketRasaUrl, options)    

Vue.prototype.$rasaWs = rasaWs