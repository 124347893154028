import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";
import VueSocketIO from "vue-socket.io";
import { rasaWs } from '@/plugins/vue-socketio.js'

const Template = () => import('@/components/Common/Template')
const NotFound = () => import('@/components/Common/NotFound')
const NotPermission = () => import('@/components/Common/NotPermission')
const ErrorComponent = () => import('@/components/Common/Error')
const HealthCheck = () => import('@/components/Common/HealthCheck')
const Home = () => import('@/pages/Home/Home')

/* Atendiento Rasa */
const CustomerServiceRasa = () => import('@/pages/CustomerServiceRasa/')
const CustomerServiceRasaTester = () => import('@/pages/CustomerServiceRasa/tester')
const ConversationRasa = () => import('@/pages/CustomerServiceRasa/chat/index')
const QuickReplyRasa = () => import('@/pages/CustomerServiceRasa/pages/QuickReply/index')
const InitiativeListRasa = () => import('@/pages/CustomerServiceRasa/pages/Initiative/InitiativeList')
const ChannelListRasa = () => import('@/pages/CustomerServiceRasa/pages/Channel/ChannelList')
const ConversationsListRasa = () => import('@/pages/CustomerServiceRasa/pages/Conversation/ConversationsList')

Vue.use(Router)

const auth = (to, from, next) => {
  store.dispatch('auth', to.query.token).then(() => {
    next('/home')
  })
}

const checkWs = (from, route) => {
  console.debug('conectando ao socket rasa-ws')
  Vue.prototype.$rasaWs.connect()
  Vue.use(
    new VueSocketIO({
      debug: true,
      connection: rasaWs,
      vuex: {
        store,
        actionPrefix: "socketRasa_",
        mutationPrefix: "socketRasa_",
      },
    })
  );
}

const checkAuth = (to, from, next) => {
  store.dispatch('check_auth').then((isAuthenticated) => {
    if (isAuthenticated) {
      next()
    } else {
      next({ path: '/authenticate', query: { redirect: to.fullPath } })
    }
  }).catch(() => {
    next('/error')
  })
  checkWs(from, to)
  store.dispatch('rasaHealthCheck/searchNewErrors')
}

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/authenticate',
      name: 'authenticate',
      beforeEnter: auth
    },
    {
      path: '',
      redirect: '/home'
    },
    {
      path: '/',
      component: Template,
      children: [
        {
          path: '/home',
          component: Home,
          name: 'home',
          beforeEnter: checkAuth
        },
        {
          path: '/customerService2/:service_id/conversation/:conversation_id',
          component: ConversationRasa,
          name: 'cs2_conversation',
          beforeEnter: checkAuth
        },
        {
          path: '/customerService2/:service_type',
          component: CustomerServiceRasa,
          name: 'cs2_loadingPage',
          beforeEnter: checkAuth,
        },
        {
          path: '/customerService2/:service_id/quick_reply/list',
          component: QuickReplyRasa,
          name: 'cs2_quickReply',
          beforeEnter: checkAuth
        },
        {
          path: '/customerService2/channel/list',
          component: ChannelListRasa,
          name: 'cs2_quickReply',
          beforeEnter: checkAuth
        },
        {
          path: '/customerService2/channel/:service_id/conversations/list',
          component: ConversationsListRasa,
          name: 'cs2_conversation_list',
          beforeEnter: checkAuth
        },        
        {
          path: '/customerService2/:service_id/initiative/list',
          name: 'cs2_initiative_list',
          component: InitiativeListRasa,
          props: true,
          beforeEnter: checkAuth,
          meta: { cacheUrl: true, cacheResult: true },
        }
      ]
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/not-permission',
      name: 'NotPermission',
      component: NotPermission
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorComponent
    },
    {
      path: '/health_check',
      name: 'health_check',
      component: HealthCheck
    },
    {
      path: '**',
      redirect: '/not-found'
    },
  ]
})

export default router
