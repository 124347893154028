import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import validationMessagesPTBR from 'vee-validate/dist/locale/pt_BR';
import validationMessagesEn from 'vee-validate/dist/locale/en';

Vue.use(VeeValidate, {
    dictionary: {
        pt: validationMessagesPTBR,
        en: validationMessagesEn
    }
});
Validator.localize('pt-BR', validationMessagesPTBR);