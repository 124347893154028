import Vue from "vue"

import store from '@/store'

import VueMask from 'v-mask'
import { VueMaskFilter } from 'v-mask'
Vue.use(VueMask);

Vue.filter('formatNumber', (n) => {
    let value = n.toString()
    let ending = ['k', 'm', 'b', 't']
    if (value.length < 4) {
        return value;
    } else {
        return `${value[0]}${value[1] != '0' ? `.${value[1]}` : ''}${ending[Math.floor((value.length - 1) / 3) - 1]}`;
    }
})

Vue.filter('VMask', VueMaskFilter)
Vue.filter('document', value => {
    var str = value + '';

    if (str.length <= 11) {
        str = str.replace(/\D/g, '')
        str = str.replace(/(\d{3})(\d)/, "$1.$2")
        str = str.replace(/(\d{3})(\d)/, "$1.$2")
        str = str.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    }
    else if (str.length > 11 && str.length <= 14) {
        str = str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    }

    return str;
})

Vue.filter('firstName', value => {
    if (!value) {
        return value
    }
    let splited = value.split(' ')
    if (splited.length > 1) {
        return splited[0] + ' ' + splited[splited.length - 1];
    } else {
        return splited[0]
    }
})

Vue.filter('firstName2', value => {
    if (!value) {
        return value
    }
    let splited = value.split(' ')
    return splited[0]
})

Vue.filter('json', value => {
    if (!value) {
        return value
    }
    return JSON.stringify(value, null, 2);
})

Vue.filter('keyToName', value => {
    if (!value) {
        return value
    }
    let strReplaced = value.split('_').join(' ')
    return strReplaced.charAt(0).toUpperCase() + strReplaced.slice(1)
})


Vue.filter('conversationStatus', value => {
    switch (value) {
        case 'OPENED':
            return 'Aberto'
        case 'REOPENED':
            return 'Reaberto'
        case 'WAITING_FOR_CUSTOMER':
            return 'Aguardando cliente'
        case 'CLOSED':
            return 'Fechado'
        case 'WAITING_FOR_TREATMENT':
            return 'Aguardando tratamento'
        case 'RESOLVED':
            return 'Resolvido'
        case 'DISCARDED':
            return 'Descartado'
        default:
            return value
    }
})

Vue.filter('dealStatus', value => {
    switch (value) {
        case 'CREATED':
            return 'Criada';
        case 'STARTED':
            return 'Iniciada';
        case 'WAITING':
            return 'Aguardando';
        case 'AGREED':
            return 'Aceita';
        case 'RESOLVED':
            return 'Finalizada';
        case 'ABORTED':
            return 'Abortada';
        case 'RENEGOTIATED':
            return 'Renegociado';
        default:
            return value;
    }
})

Vue.filter('firstLetter', value => {
    if (!value) {
        return value
    }
    return value.length ? value[0] : value
})

Vue.filter('percentage', function (value, decimalPlaces) {
    if (!decimalPlaces) {
        decimalPlaces = 0
    }
    return (parseFloat(value) * 100).toFixed(decimalPlaces) + "%"
})

Vue.filter('limitTo', function (value, limit) {
    if (!value) {
        return value
    }
    return value.substring(0, limit)
})

Vue.filter('truncate', function (value, limit) {
    if (!value) {
        return value
    }
    return value.length > limit ? value.substring(0, limit) + '...' : value
})
Vue.filter('IntentName', value => value.name)